<template>
  <v-select
    v-model="selected"
    outlined
    :items="roles"
    :label="$trans('choose_role')"
    :height="selected ? undefined : 10"
    :item-text="getName"
    item-value="id"
    :disabled="disabled"
    @change="$emit('change', selected)"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RolesField",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    rules: {
      type: [Array, Object],
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    ...mapGetters({
      roles: "role/getRoles",
    }),
  },
  watch: {
    value() {
      this.selected = this.value;
    },
  },
  created() {
    this.fetchRoles({ ascending: 1 });
  },
  methods: {
    ...mapActions({
      fetchRoles: "role/fetch",
    }),
    getName(item) {
      if (item.name) {
        return this.$trans(`role_${item.name}`);
      }
    },
  },
};
</script>
